@tailwind base;
@tailwind components;
@tailwind utilities;

/* Base styles */
@layer base {
  html {
    scroll-behavior: smooth;
  }

  body {
    @apply bg-black text-white antialiased;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ::selection {
    @apply bg-blue-500/20;
  }
}

/* Animation Keyframes */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@keyframes pulse {
  0%, 100% {
    opacity: 0.8;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
}

@keyframes glow {
  0%, 100% {
    opacity: 0.5;
    filter: blur(10px);
  }
  50% {
    opacity: 0.7;
    filter: blur(15px);
  }
}

@keyframes scan {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes drawLine {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

/* Custom Animation Classes */
@layer utilities {
  .animate-float {
    animation: float 6s ease-in-out infinite;
  }

  .animate-pulse-slow {
    animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }

  .animate-glow {
    animation: glow 3s ease-in-out infinite;
  }

  .animate-scan {
    animation: scan 3s linear infinite;
  }

  .animate-dash {
    animation: dash 2s ease-out forwards;
  }

  .animate-draw {
    animation: drawLine 2s ease-out forwards;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }

  .animate-fade-in {
    animation: fadeIn 0.5s ease-out forwards;
  }
}

/* Gradient Text */
.gradient-text {
  @apply bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-blue-600;
}

/* Glass Effect */
.glass {
  @apply bg-white/5 backdrop-blur-sm border border-white/10;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  @apply w-2;
}

::-webkit-scrollbar-track {
  @apply bg-black;
}

::-webkit-scrollbar-thumb {
  @apply bg-white/10 rounded-full hover:bg-white/20 transition-colors;
}

/* Animation Delays */
.delay-2 {
  animation-delay: 200ms;
}

.delay-4 {
  animation-delay: 400ms;
}

.delay-6 {
  animation-delay: 600ms;
}